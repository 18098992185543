import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledDiv = styled.div`
  background: #F7BE04;
`

const Container = 'relative flex flex-col items-center mt-8 px-4 pt-8 pb-4 sm:p-0 sm:mb-6'
const Title = 'font-display font-bold text-xl md:text-lg text-orange-900 mt-0 mb-4'
const cardImage = 'w-full rounded shadow-lg'

const HomeProductsSection = () => (
  <StaticQuery
    query={query}
    render={
      data => {
        const imageBrooks = data.imageBrooks.childImageSharp.fluid
        const imageFirePro = data.imageFirePro.childImageSharp.fluid
        const imageHandPort = data.imageHandPort.childImageSharp.fluid
        const imageJL = data.imageJL.childImageSharp.fluid
        const imageRestSys = data.imageRestSys.childImageSharp.fluid
        const imageWheeled = data.imageWheeled.childImageSharp.fluid
        const imageGSA = data.imageGSA.childImageSharp.fluid

        return (
          <StyledDiv className='p-24 sm:px-4 md:px-8 sm:py-12'>
            <div className='flex flex-col sm:w-11/12 md:w-11/12 w-10/12 xl:w-9/12 items-center font-body justify-center mx-auto'>
              <h2 className='font-display text-4xl sm:text-2xl text-white font-medium text-center'>We are an Authorized Distributor of these Fire Safety Products:</h2>
            </div>

            <div className='grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-6 sm:gap-0 sm:w-full w-11/12 xl:w-9/12 mx-auto mb-12 sm:mb-0'>
              <a href='https://amerex-fire.com/products/amerex-restaurant-systems/' target='_blank' rel='noopener noreferrer'>
                <div className={Container}>
                  <h2 className={Title}>Restaurant Systems</h2>
                  <Img fluid={imageRestSys} alt='Restaurant Systems' className={cardImage} />
                </div>
              </a>

              <a href='https://amerex-fire.com/products/?category=hand-portable-extinguishers' target='_blank' rel='noopener noreferrer'>
                <div className={Container}>
                  <h2 className={Title}>Hand Portable Extinguishers</h2>
                  <Img fluid={imageHandPort} alt='Hand Portable Extinguishers' className={cardImage} />
                </div>
              </a>

              <a href='https://amerex-fire.com/products/?category=wheeled-extinguishers' target='_blank' rel='noopener noreferrer'>
                <div className={Container}>
                  <h2 className={Title}>Wheeled Extinguishers</h2>
                  <Img fluid={imageWheeled} alt='Wheeled Extinguishers' className={cardImage} />
                </div>
              </a>

              <a href='https://www.activarcpg.com/jl-industries/fire-protection-3' target='_blank' rel='noopener noreferrer'>
                <div className={Container}>
                  <h2 className={Title}>JL Industries</h2>
                  <Img fluid={imageJL} alt='JL Industries' className={cardImage} />
                </div>
              </a>

              <a href='https://www.potterroemer.com' target='_blank' rel='noopener noreferrer'>
                <div className={Container}>
                  <h2 className={Title}>Potter Roemer / Fire Pro</h2>
                  <Img fluid={imageFirePro} alt='Potter Roemer / Fire Pro' className={cardImage} />
                </div>
              </a>

              <a href='https://www.brooksequipment.com' target='_blank' rel='noopener noreferrer'>
                <div className={Container}>
                  <h2 className={Title}>Brooks Equipment</h2>
                  <Img fluid={imageBrooks} alt='Brooks Equipment' className={cardImage} />
                </div>
              </a>
            </div>

            <div className='flex flex-col sm:11/12 w-10/12 xl:w-9/12 items-center font-body justify-center mx-auto'>
              <p className='text-white text-xl font-medium text-center'>South Bay Fire was recently awarded a Schedule 84 GSA Contract, #47QSWA20D0070.</p>

              <Img fluid={imageGSA} alt='GSA logo' className='w-64 mt-6 rounded shadow-md' />
            </div>

            <div className='flex sm:w-full w-10/12 justify-center mx-auto'>
              <a href='https://www.gsaadvantage.gov/advantage/ws/search/advantage_search?db=0&searchType=1&q=19:547QSWA20D0070&src=elib' target='_blank' rel='noopener noreferrer'>
                <button className="bg-red-100 hover:bg-white text-red-600 text-lg font-bold py-3 px-8 rounded-full border-b-4 border-orange-700 mt-8">
                  View Product Catalog
                </button>
              </a>
            </div>
          </StyledDiv>
        )
      }
    }
  />
)

export default HomeProductsSection

const query = graphql`
  query HomeProductsSectionQuery {
    imageBrooks: file(relativePath: { eq: "products/brooks-equipment.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageFirePro: file(relativePath: { eq: "products/fire-protections.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageHandPort: file(relativePath: { eq: "products/hand-portable-extinguishers.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageJL: file(relativePath: { eq: "products/jl-industries.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageRestSys: file(relativePath: { eq: "products/restaurant-systems.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageWheeled: file(relativePath: { eq: "products/wheeled-extinguishers-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageGSA: file(relativePath: { eq: "logo/gsa-advantage-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`