import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import styled from 'styled-components'

import HeaderNav from '../navigation/HeaderNav'

const StyledDiv = styled.div`
  background: #f7bd01;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  @media (min-width:1920px) {
    height: 800px;
  }

  @media (min-width:768px) and (max-width:1024px) {
    height: 1000px;
  }

  @media (max-width:767px) {
    height: 1000px;
  }
`

const LoadHomeHeroText = loadable(() => pMinDelay(import('./HomeHeroTextNew'), 1000))

const HomeHeroHeader = () => (
  <StyledDiv>
    <HeaderNav />
    <LoadHomeHeroText />
  </StyledDiv>
)

export default HomeHeroHeader