import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import HomeHeroHeaderNew from '../components/headers/HomeHeroHeaderNew'
import HomeStakesSection from "../components/sections/HomeStakesSection"
import HomeBenefitsSection from "../components/sections/HomeBenefitsSection"
import HomeAboutSection from "../components/sections/HomeAboutSection"
import HomeTestiSection from "../components/sections/HomeTestiSection"
import HomeServicesSection from "../components/sections/HomeServicesSection"
import HomeProductsSection from "../components/sections/HomeProductsSection"

export default () => (
  <HomeContainer>
    <HeadData isHome />

    <HomeHeroHeaderNew />

    <HomeStakesSection />

    <HomeProductsSection />

    <HomeBenefitsSection />

    <HomeServicesSection />

    <HomeTestiSection />

    <HomeAboutSection />
  </HomeContainer>
)