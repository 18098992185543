import React from 'react'

import Icon1 from '../../assets/icon-1.svg'
import Icon2 from '../../assets/icon-2.svg'
import Icon3 from '../../assets/icon-3.svg'

const Container = 'flex flex-col items-center w-full text-center p-4 sm:p-0 sm:mb-12 md:mb-12'

const Icon = 'border-4 border-red-200 rounded-full'

const Title = 'font-display text-2xl text-gray-900 font-semibold mt-4 mb-2'

const Parag = 'text-gray-800 font-body'

const HomeBenefitsSection = () => (
  <div className='bg-orange-100 px-16 py-24 sm:py-12 sm:px-4'>
    <div className='grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 sm:w-11/12 w-11/12 xl:w-8/12 mx-auto mb-12'>
      <div className={Container}>
        <Icon1 className={Icon} />
        <h2 className={Title}>Great Customer Service</h2>
        <p className={Parag}>Our knowledgeable technicians and friendly office staff are here to assist you with all of your fire protection needs.</p>
      </div>

      <div className={Container}>
        <Icon2 className={Icon} />
        <h2 className={Title}>Eliminate Costly Citations</h2>
        <p className={Parag}>We will track the required services needed and notify you at the appropriate times, so you can save money.</p>
      </div>

      <div className={Container}>
        <Icon3 className={Icon} />
        <h2 className={Title}>Peace of Mind</h2>
        <p className={Parag}>You can sleep better at night knowing that you and your business will be safe in case of a fire.</p>
      </div>
    </div>

    <div className='flex sm:w-full w-10/12 justify-center mx-auto'>
      <a href='tel:310-376-6901'>
        <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8">
          <svg class="fill-current text-white inline-block h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/>
          </svg>
          (310) 376-6901
        </button>
      </a>
    </div>
  </div>
)

export default HomeBenefitsSection