import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Icon4 from '../../assets/icon-4.svg'
import Icon5 from '../../assets/icon-5.svg'
import Icon6 from '../../assets/icon-6.svg'

const StyledDiv = styled.div`
  background: #cb2d3e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ef473a, #cb2d3e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ef473a, #cb2d3e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const Container = 'relative flex flex-col items-center mt-12 px-4 pt-8 pb-4 sm:p-0 sm:mb-6'

const Icon = 'absolute top-0 -mt-4 sm:-mt-12 w-24 border-4 border-white rounded-full'

const ContentDiv = 'bg-white p-8 pt-16 text-center rounded shadow-lg overflow-hidden'

const Title = 'font-display font-bold text-2xl text-orange-900 mt-0 mb-4'

const Parag = 'text-gray-800 font-body'

const HomeServicesSection = () => (
  <StyledDiv className='p-24 sm:px-4 sm:py-12'>
    <div className='flex flex-col sm:11/12 w-10/12 xl:w-8/12 items-center font-body justify-center mx-auto mb-12 sm:mb-20'>
      <h2 className='font-display text-4xl sm:text-2xl text-white text-center'>Our Services</h2>
    </div>

    <div className='grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-6 sm:w-full w-11/12 xl:w-8/12 mx-auto mb-12 sm:mb-0'>
      <div className={Container}>
        <Icon4 className={Icon} />
        <div className={ContentDiv}>
          <h2 className={Title}>Fire Extinguisher</h2>
          <p className={Parag}>South Bay Fire, Inc. specializes in portable fire extinguisher sales, inspection, recharge & repairs. California Title 19 Life and Safety code require fire extinguishers to be serviced and certified annually.</p>
        </div>
      </div>

      <div className={Container}>
        <Icon5 className={Icon} />
        <div className={ContentDiv}>
          <h2 className={Title}>Fire Sprinkler & Standpipes</h2>
          <p className={Parag}>Allow our experienced and knowledgeable fire protection contractors to test, service and maintain your sprinkler and standpipe system per NFPA 25 as amended by CCR Title 19.</p>
        </div>
      </div>

      <div className={Container}>
        <Icon6 className={Icon} />
        <div className={ContentDiv}>
          <h2 className={Title}>Fire Suppression</h2>
          <p className={Parag}>At South Bay Fire Inc., we plan, design, install and perform required semi-annual service for commercial kitchen fire suppression systems. Commercial kitchens are one of the most risky locations in which fires start.</p>
        </div>
      </div>
    </div>

    <div className='flex sm:w-full w-10/12 justify-center mx-auto'>
      <Link to='/services'>
        <button className="bg-red-100 hover:bg-white text-red-600 text-lg font-bold py-3 px-8 rounded-full border-b-4 border-orange-700 mt-8">
          Learn More
        </button>
      </Link>
    </div>
  </StyledDiv>
)

export default HomeServicesSection