import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Container = 'w-full flex flex-col items-center text-center p-4 sm:p-0 sm:mb-12 md:mb-12'

const Title = 'font-display text-2xl text-gray-800 mt-4 mb-2'

const Parag = 'text-gray-900 font-body italic'

const HomeTestiSection = () => (
  <StaticQuery
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid
        const imageDataTwo = data.imageTwo.childImageSharp.fluid
        const imageDataThree = data.imageThree.childImageSharp.fluid

        return (
          <div className='bg-orange-200 p-24 sm:px-4 sm:py-12'>
            <div className='flex flex-col sm:w-full w-10/12 xl:w-8/12 items-center font-body justify-center mx-auto mb-12'>
              <h2 className='font-display text-4xl sm:text-2xl text-orange-900 text-center'>What Customers Say</h2>
            </div>

            <div className='grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 sm:w-full w-10/12 xl:w-8/12 mx-auto'>
              <div className={Container}>
                <Img fluid={imageDataOne} alt='Image of a male customer' className='w-24 rounded-full' />
                <h2 className={Title}>Russ S.</h2>
                <p className={Parag}>"Serving your fire extinguisher seems like a simple but annoying need, but they made it one of the most pleasurable and easy annual tasks."</p>
              </div>

              <div className={Container}>
              <Img fluid={imageDataTwo} alt='Image of a female customer' className='w-24 rounded-full' />
                <h2 className={Title}>Charlotte L.</h2>
                <p className={Parag}>"Gosh, this is the best place for anyone that has fire extinguishers to be recharged or even replaced. It's a family business for years and fire captains throughout."</p>
              </div>

              <div className={Container}>
              <Img fluid={imageDataThree} alt='Image of a male customer' className='w-24 rounded-full' />
                <h2 className={Title}>Tony B.</h2>
                <p className={Parag}>"Took my CO2 tank in. They filled it for me and gave me tips and washers just in case I didn't have them. Price was great and I'm definitely coming back."</p>
              </div>
            </div>

            <div className='flex sm:w-full w-10/12 justify-center mx-auto'>
              <a href='tel:310-376-6901'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8">
                  <svg class="fill-current text-white inline-block h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/>
                  </svg>
                  (310) 376-6901
                </button>
              </a>
            </div>
          </div>
        )
      }
    }
  />
)

export default HomeTestiSection

const query = graphql`
  query HomeTestiSectionQuery {
    imageOne: file(relativePath: { eq: "sections/testi-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageTwo: file(relativePath: { eq: "sections/testi-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThree: file(relativePath: { eq: "sections/testi-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
