import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #fff5f5;
`

const List = 'font-body text-gray-800 ml-10 mb-1'

const HomeStakesSection = () => (
  <StaticQuery
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid

        return (
          <StyledDiv>
            <div className='sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-8/12 py-0 mx-auto'>
              <div className='bg-red-100 py-24 sm:py-12 flex sm:flex-col md:flex-col items-center'>
                <div className='sm:w-full md:w-full sm:mb-8 md:mb-8 w-1/2'>
                  <Img className='sm:w-full w-11/12 z-0 rounded shadow-lg' fluid={imageDataOne} alt='image' />
                </div>

                <div className='sm:w-full md:w-full w-1/2 flex flex-col justify-center'>
                  <h2 className='font-display text-4xl sm:text-2xl md:text-3xl text-orange-900 leading-tight mb-12'>Getting fire safety-related services and products should not be a painful and costly process.</h2>

                  <p className='text-gray-900 font-body mb-4'>Have you felt frustrated by other fire protection companies?</p>

                  <ul className='list-disc'>
                    <li className={List}>Tired of technicians not showing up?</li>
                    <li className={List}>Most companies giving you expensive and unreasonable quotes?</li>
                    <li className={List}>Don't trust other contractors with your home or business?</li>
                    <li className={List}>Are you dealing with fire department citation that needs quick and professional attention?</li>
                  </ul>

                  <a href='tel:310-376-6901'>
                    <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8">
                      <svg class="fill-current text-white inline-block h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/>
                      </svg>
                      (310) 376-6901
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </StyledDiv>
        )
      }
    }
  />
)

export default HomeStakesSection

const query = graphql`
  query HomeStakesSectionQuery {
    imageOne: file(relativePath: { eq: "sections/section-stakes-photo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 612) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`