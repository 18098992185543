import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #e65c00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #e65c00, #F9D423);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e65c00, #F9D423); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const HomeAboutSection = () => (
  <StaticQuery
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid

        return (
          <StyledDiv>
            <div className='sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-8/12 sm:py-12 py-24 mx-auto'>
              <div className='flex sm:flex-col-reverse md:flex-col-reverse items-center'>
                <div className='sm:w-full md:w-11/12 w-1/2 flex flex-col justify-center mr-4 sm:mr-0'>
                  <h2 className='font-display text-4xl sm:text-2xl text-white leading-tight mb-12'>Our goal is to ensure your property is properly equipped to handle any fire emergency.</h2>

                  <p className='text-white font-body mb-4'>South Bay Fire, Inc. has been family owned and operated since 1954, serving the Greater Los Angeles area. Founded by well respected World War II veteran, and City of Manhattan Beach fire captain, Howard A. Freeman, we take pride in providing quality fire protection products. As an authorized distributor of Amerex fire equipment, we offer a wide variety of fire extinguishers and accessories suitable for virtually any application.</p>

                  <p className='text-white font-body mb-4'>At South Bay Fire, Inc., we are committed to protecting lives and property. No matter how small or large the job, our highly trained team does it all. Contact us today so we can help ensure your environment is fire safe.</p>

                  <a href='tel:310-376-6901'>
                    <button className="bg-red-100 hover:bg-white text-red-600 text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8">
                      <svg class="fill-current text-red-600 inline-block h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/>
                      </svg>
                      (310) 376-6901
                    </button>
                  </a>
                </div>

                <div className='sm:w-full md:w-8/12 sm:mt-4 md:mb-12 w-1/2'>
                  <Img className='sm:w-full w-11/12 z-0 rounded-full shadow-lg m-6 sm:mx-0 sm:mb-8 sm:mt-0' fluid={imageDataOne} alt='image' />
                </div>
              </div>
            </div>
          </StyledDiv>
        )
      }
    }
  />
)

export default HomeAboutSection

const query = graphql`
  query HomeAboutSectionQuery {
    imageOne: file(relativePath: { eq: "sections/home-about-section.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`